export interface IConfiguration {
    readonly id: any;
    name: string
    description: string
}

export class Configuration {
    public id!: any;
    public _method!: string;
	public name!: string;
	public value!: string;
 
}