import { Configuration, configurationApi } from "./index"
import environment from "@/config"

export function configurationFactory() {
    const module = 'configuration';
    const api = configurationApi;
    const base_url = environment.API_URL + '/reference'// + module;
    const unit_url = base_url + '/sensor-unit';
    const job_url = base_url + '/type-of-job';
    const position_url = base_url + '/user-position';

    async function getConfigurations() : Promise<[Configuration]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getConfiguration(id: any): Promise<Configuration> {
        let response = await api.first(id)
        return response.data;
    }

    async function createConfiguration(configuration: Configuration): Promise<Configuration> {
        let response = await api.create(configuration)
        return response.data;
    }

    async function updateConfiguration(configuration: Configuration): Promise<Configuration> {
        let response = await api.edit(configuration.id, configuration)
        return response.data;
    }

    // FIXME: not working
    async function destroyConfiguration(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    return {
        api,
        base_url,
        unit_url,
        job_url,
        position_url,
        getConfigurations,
        getConfiguration,
        createConfiguration,
        updateConfiguration,
        destroyConfiguration,
    }

}

