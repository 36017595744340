import { Vue, setup } from 'vue-class-component';
import { Configuration, configurationFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import storage from "@/storage";
export default class CreateConfigurationPage extends Vue {
    configurationBloc = setup(() => configurationFactory())
    configuration = new Configuration
    value: any = '';
    nameOptions: any = {};

    created() {
        this.nameOptions = this.initOption(environment.API_URL + '/reference/select2/configuration');
    }

    initOption(url: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    updateNameOption(data: any[]) {
        this.nameOptions = {
            ...this.nameOptions,
            data: data,
        }
    }

    nameSelectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateNameOption(data);
        this.configuration.name = data[0].text;
        if (this.configuration.name == 'User Position') {
            this.value = "Name";
        } else if (this.configuration.name == 'Type of Job') {
            this.value = "Action";
        } else if (this.configuration.name == 'Sensor Unit') {
            this.value = "Unit";
        }
    }

    async save() {
        try {
            this.configuration = await this.configurationBloc.createConfiguration(this.configuration);
            widget.alertSuccess('Good Job!', 'You have successfully created a Configuration');
            this.$router.push(`/configuration/${this.configuration.id}`)
        } catch (error) {
            widget.alertError(error);
        }
    }
}

