import { widget } from "@cems-eco/core/src/utils/widget";
import { configurationFactory } from "../../services";
import { Options, setup, Vue } from "vue-class-component";
import $ from "jquery";
import storage from "@/storage";
@Options({
  components: {
  }
})

export default class JobComponent extends Vue {

  configurationBloc = setup(() => configurationFactory())
  configurations: object[] = []
  options = {}
  events: object[] = []

  mounted() {
    this.builDataTable()
  }

  async builDataTable() {
    const loader: any = await widget.loading();
    const scope: any = this;
    loader.showLoading();
    let headers: any = {
        responseType: "json",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    let auth = storage.getSync<any>(storage.AUTH);
    if (auth) {
        headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
    this.options = {
      serverSide: true,
      ajax: {
        url: this.configurationBloc.job_url,
        method: 'GET',
        dataType: 'json',
        dataFilter: (data) => {
          const parsedData = JSON.parse(data);
          return JSON.stringify(parsedData.data);
        },
        headers,
      },
      columnDefs: [
          {
              "targets": -1,
              "width": "1%"
          },
      ],
      mobileView: {
          header: {
              actions: [
                  {
                      id: 'viewAction',
                      template: '<i class="far fa-eye text-success" title="View" ></i> View',
                  },
                  {
                      id: 'editAction',
                      template: '<i class="fas fa-edit text-primary" title="View" ></i> Update',
                  },
                  {
                      id: 'deleteAction',
                      template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                  },
              ],
              title: function (data: any) {
                  return 'Type of Job';
              },
          }
      },
      columns: [
        { data: 'value', title: "Value",mobileView: true },
        {
          title: "Action",
          mobileView: false,
          data: "id",
          render: function () {
            return (
              '<div class="form-group" style="margin: auto;">' +
              '<div class="btn-group" role="group">' +
              `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
              `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
              `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
              "</div>" +
              "</div>"
            );
          }
        }
      ],
      createdRow: function (row: any, data: any) {
        $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
        $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
        $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
      }
    };
    loader.close();
  }

  async viewData(data: any) {
    this.$router.push(`/configuration/${data.id}`);
  }

  async editData(data: any) {
    this.$router.push(`/configuration/${data.id}/edit`);
  }

  async deleteData(data: any) {
    widget.alertDelete().then(async (result) => {
      try {
        if (!result.isConfirmed) {
          widget.alertSuccess('Deletion abort.', 'Your data is save.')
          return;
        }
        let message = await this.configurationBloc.destroyConfiguration(data.id);
        widget.alertSuccess('Good Job!', 'Your data has been deleted.');
        this.builDataTable();
      } catch (error) {
        widget.alertError(error);
      }
    })
  }
}