import { Vue, setup } from 'vue-class-component';
import { Configuration, configurationFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";

export default class ViewConfigurationPage extends Vue {
    configurationBloc = setup(() => configurationFactory())
    configuration = new Configuration
    value: any = '';

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.configuration.id = this.$route.params.id;
        this.configuration = await this.configurationBloc.getConfiguration(this.configuration.id);
        if (this.configuration.name == 'User Position') {
            this.value = "Name";
        } else if (this.configuration.name == 'Type of Job') {
            this.value = "Action";
        } else if (this.configuration.name == 'Sensor Unit') {
            this.value = "Unit";
        }
        loader.close();
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.configurationBloc.destroyConfiguration(this.configuration.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/configuration');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}
