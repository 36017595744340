import { Vue, Options } from "vue-class-component";
import { Tab } from "@cems-eco/core/src/models";
import component from "../components/component";

@Options({
    components: component,
})
export default class ConfigurationPage extends Vue {
    
    tabs: Tab
    activeTabIndex: number = 0;

    async created() {
        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'unit',
                name: 'Sensor Unit',
                icon: 'ni ni-sound-wave',
                active: true,
                component: 'UnitComponent'
            },
            {
                id: 'job',
                name: 'Type of Job',
                icon: 'far fa-file-alt',
                active: false,
                component: 'JobComponent'
            },
            {
                id: 'postion',
                name: 'User Position',
                icon: 'ni ni-single-02',
                active: false,
                component: 'PositionComponent'
            },
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }

}

